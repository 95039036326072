import React from 'react';
import './SearchColumn.css';
import './ContactForm.css';
// Define the component to fetch and display data
import BusinessStatus from './BusinessStatus';
// import { useTranslation } from 'react-i18next';
import configDetails from './../components/utils/ConfigDetails';
const ContactForm = () => {
  // const { t } = useTranslation();
    const handleEmailClick = () => {
        window.location.href = `mailto:${configDetails.email}`;
      };
   
      const handlePhoneClick = () => {
        window.location.href = `tel:${configDetails.sw}`; // Phone number with country code
      };
    
      const handleWhatsAppClick = () => {
        const phone = configDetails.wappsw; // WhatsApp phone number with country code
        const message = 'Hello, I would like more information...'; // Custom message
        const url = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank'); // Open WhatsApp in a new tab
      };
      
  // Render the fetched data
  return (
     <div>
    <div className='searchDiv'>
  
  <div className="vertical-stack"> {/* Parent container for vertical layout */}
     

      <p align="center">
        <img src='/support.png' alt='Call us '></img>
        <p align="center" className="marginBottomNone">
          <span style={{fontWeight:'bold', fontSize:'18px'}}>
          Sales Department.
          </span>
        </p>
       </p>
       
       <p align="center" className="marginBottomNone">
       <span  onClick={handleEmailClick} ><span style={{fontWeight:'bold'}}>Email:</span> {configDetails.email} </span> <br></br>
       </p>
       <p align="center" className="marginBottomNone" style={{display:'none'}} >
       <span  onClick={handleEmailClick} ><span style={{fontWeight:'bold', visibility:'hidden'}}>Email:</span> {configDetails.email1} </span> <br></br>
       </p>
       <p align="center" className="marginBottomNone"  style={{display:'none'}}>
       <span  onClick={handlePhoneClick}><span style={{fontWeight:'bold'}}>Phone:</span> {configDetails.swFormatted} Mark Clifford</span>
       </p>

       <p align="center" className="marginBottomNone" style={{display:'none'}}>
       <span   onClick={handleWhatsAppClick} ><span style={{fontWeight:'bold'}}>WhatsApp:</span> {configDetails.wappswFormatted}</span>
       </p> 

       <br></br>
        <div style={{border:'1px solid black'}}>
       <p align="center" className="marginBottomNone">
       <span   >Monday - Friday 09:00 to 19:00</span>
       </p>

       <p align="center" className="">
       <span  > Saturday - 10:00 to 16:00</span>
       </p>
       <p align="center">
        <span> Sunday closed</span>
       </p>
       </div>
       
 <br/> <br/> <br/>
       <p align="center">
       <BusinessStatus className="businesstatuscontact"/>
       </p>

      
    </div>
  </div>

  

  </div>
  );
};

export default ContactForm;
