// configDetails.js
const configDetails = {
    swFormatted: "+44 1392 58 1762",
    sw: '+441392581762',
    wappsw: "+441392581762",
    wappswFormatted: "+44 1392 58 1762",
    email: "gahmotorsltd@gmail.com",
    email1: "sales@gahmotors.co.uk",
    spain: "+44 1392 58 1762",
    infoEmail: 'gahmotorsltd@gmail.com',
    emailSpanish:'gahmotorsltd@gmail.com',
    organizationnumber: '14832861',
    vatnumber: '14832861',
    numarSunat: '+44 1392 58 1826',
    numarSunatF: '+441392581826'

};

// +44 1392 58 1762
// +441392581762
export default configDetails;



